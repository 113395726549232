/**
 * These theme values will override the base theme.
 *
 * We're passing these values to Layout component;
 * The Layout component merges these values with the
 * base theme.
 *
 */

 export default {
    colors: {
      background: `#fbfbfb`,
      headerBg: `#FFFFFF`,
      footerBg: `#f1f3f8`
    }
  }
  